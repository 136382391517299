.App {
  text-align: center;
}

.btn {
  width: 100px; /* Adjust the width as per your requirement */
}

.banner {
  background-color: #333;
  color: white;
  height: 8vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner h1 {
  margin-left: 20px;
  font-size: 24px;
}

.logo img {
  height: 80px; /* Adjust according to your logo size */
}

header {
  background-color: #333;
  padding: 10px 0;
}

.login {
  float: right;
}

.login button {
  background-color: #4CAF50;
  color: white;
  padding: 8px 16px;
  border: none;
  cursor: pointer;
}

.login button:hover {
  background-color: #45a049;
}

.tabs {
  margin-top: 20px;
}

.tabs button {
  background-color: #333;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}

.tabs button.active {
  background-color: #4CAF50;
}

.tab-content {
  margin-top: 20px;
  font-size: 18px;
}